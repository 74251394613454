<template>
  <div class="user-info">
    <div class="user-avatar-container">
      <img class="user-avatar" :src="user.photo" alt="avatar" />
    </div>
    
    <div class="user-details">
      <div class="user-header">
        <div class="user-name">
          <span class="username-text">{{ user.name }}</span>
          <a
            target="_blank"
            :href="'http://steamcommunity.com/profiles/' + user.steamID"
            class="steam-id"
          >({{ user.steamID }})</a>
          <span v-if="user.steamID === '76561198378224497'" class="special-tag">&lt;-- pussyboy</span>
        </div>
      </div>
      
      <div class="role-selector">
        <label for="role-select" class="role-label">Role:</label>
        <select id="role-select" v-model="role" @change="changeRole" class="role-dropdown">
          <option disabled value=""></option>
          <option value="owner">Owner</option>
          <option value="admin">Admin</option>
          <option value="mod">Mod</option>
          <option value="helper">Helper</option>
          <option value="verified">Verified</option>
          <option value="user">User</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserInfo',
    emits: ['updateUser'],
    props: {
      user: {
        type: Object
      }
    },
    watch: {
      user: {
        handler: function (val) {
          this.role = val.role
        },
        deep: true
      }
    },
    data() {
      return {
        role: ''
      }
    },
    mounted() {
      this.role = this.user.role
    },
    methods: {
      changeRole() {
        this.$emit('updateUser', 'role', this.role)
      }
    }
  }
</script>

<style scoped>
.user-info {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.user-avatar-container {
  flex-shrink: 0;
}

.user-avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 0.75rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.2s ease;
}

.user-avatar:hover {
  transform: scale(1.05);
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  padding-top: 0.25rem;
}

.user-header {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.user-name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.username-text {
  font-weight: 600;
  font-size: 1.25rem;
  color: white;
}

.special-tag {
  font-size: 0.85rem;
  color: #FFA500;
  opacity: 0.8;
}

.steam-id {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  transition: color 0.2s ease;
}

.steam-id:hover {
  color: rgba(51, 142, 255, 1);
  text-decoration: underline;
}

.role-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.25rem;
}

.role-label {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.role-dropdown {
  background-color: #1a1f25;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.375rem;
  color: white;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  outline: none;
  cursor: pointer;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  min-width: 120px;
}

.role-dropdown:focus {
  border-color: rgba(51, 142, 255, 0.5);
  box-shadow: 0 0 0 2px rgba(51, 142, 255, 0.25);
}

@media (max-width: 640px) {
  .user-info {
    flex-direction: column;
    align-items: center;
  }
  
  .user-avatar-container {
    display: flex;
    justify-content: center;
  }
  
  .user-header, .user-name {
    text-align: center;
    justify-content: center;
  }
  
  .role-selector {
    justify-content: center;
  }
}
</style>
