<template>
  <div class="search-container">
    <input 
      type="text" 
      class="search-input" 
      placeholder="Enter a Steam ID" 
      v-model="steamID"
    />
    <Button 
      class="search-button" 
      label="Search" 
      @click="$emit('search', steamID)" 
      background-color="rgba(51, 142, 255, 1)"
    />
  </div>
</template>

<script>
import Button from '@/components/Button'
import _ from 'lodash'
import { useToast } from 'vue-toastification'

export default {
  name: 'Search',
  components: { Button },
  data() {
    return {
      steamID: '',
    }
  },
  props: {
    query: {
      type: String,
      default: ''
    },
  },
  emits: ['search'],
  setup() {
    const toast = useToast();
    return {toast}
  },
  mounted() {
    if(!_.isEmpty(this.query)) {
      this.steamID = this.query
      this.$emit('search', this.steamID)
    }
  },
}
</script>

<style scoped>
.search-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

@media (min-width: 640px) {
  .search-container {
    flex-direction: row;
  }
}

.search-input {
  flex: 1;
  height: 3.25rem;
  padding: 0 1rem;
  font-size: 1rem;
  background-color: #1a1f25;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: white;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  appearance: none;
  min-height: 48px;
  box-sizing: border-box;
}

.search-input:focus {
  outline: none;
  border-color: rgba(51, 142, 255, 0.5);
  box-shadow: 0 0 0 2px rgba(51, 142, 255, 0.25);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.search-button {
  height: 3.25rem;
  border-radius: 0.5rem;
  padding: 0 1.5rem;
  font-weight: 500;
  transition: transform 0.2s ease, opacity 0.2s ease;
  min-height: 48px;
}

.search-button:hover {
  transform: translateY(-1px);
  opacity: 0.95;
}

@media (max-width: 639px) {
  .search-button {
    width: 100%;
  }
}
</style>