<template>
  <button 
    class="btn" 
    :class="[
      size, 
      { 'has-icon': icon !== undefined }
    ]"
    :style="{
      color: textColor,
      background: backgroundColor
    }"
    @click="$emit('click', $event)"
  >
    <i v-if="icon" :class="[icon, { 'with-label': label !== '' }]"></i>
    <span v-if="label" class="btn-label">{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    label: {
      default: "",
      type: String,
    },
    icon: {
      default: undefined,
      type: String,
    },
    textColor: {
      default: "#fff",
      type: String,
    },
    backgroundColor: {
      default: "rgba(51, 142, 255, 1)",
      type: String,
    },
    size: {
      default: "medium",
      type: String,
      validator: (value) => ["small", "medium", "large"].includes(value)
    }
  },
  emits: ['click']
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  outline: none;
  position: relative;
  transition: all 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    filter: brightness(1.05);
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    filter: brightness(0.95);
  }
  
  &:focus {
    box-shadow: 0 0 0 3px rgba(51, 142, 255, 0.3);
  }
  
  &.small {
    font-size: 0.8rem;
    padding: 0.375rem 0.75rem;
    min-height: 2rem;
  }
  
  &.medium {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    min-height: 2.5rem;
  }
  
  &.large {
    font-size: 1rem;
    padding: 0.625rem 1.25rem;
    min-height: 3rem;
  }
  
  &.has-icon {
    padding-left: 0.75rem;
  }
}

.btn-label {
  display: inline-block;
  font-weight: inherit;
}

i.with-label {
  margin-right: 0.5rem;
}

@media (max-width: 640px) {
  .btn {
    &.small {
      font-size: 0.75rem;
      padding: 0.3rem 0.625rem;
    }
    
    &.medium {
      font-size: 0.85rem;
      padding: 0.45rem 0.875rem;
    }
    
    &.large {
      font-size: 0.95rem;
      padding: 0.5rem 1rem;
    }
  }
}
</style>