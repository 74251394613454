<template>
  <div class="users-container">
    <!-- User Profile Section -->
    <section class="panel user-profile-panel">
      <h2 class="panel-title">User Profile</h2>
      <div class="panel-content">
        <div class="search-container">
          <Search @search="loadUser" :query="$route.params.steamID ? $route.params.steamID.toString() : ''" />
        </div>
        
        <div v-if="user" class="user-profile-content">
          <div class="card-grid">
            <div class="card">
              <UserInfo :user="user" @updateUser="updateUser" />
            </div>
            <div class="card">
              <UserStats :user="user" />
            </div>
          </div>
          
          <div class="card">
            <a class="trade-url" target="_blank" :href="user.trade_url">{{ user.trade_url }}</a>
          </div>
          
          <div class="card">
            <div class="steam-checks">
              <div class="check-item">
                <label for="steam_level">Steam Level:</label>
                <div class="toggle-container">
                  <input type="checkbox" id="steam_level" v-model="steam_level" @change="updateUser('steam_info.steam_level', !steam_level ? 0 : 10)" />
                  <span class="level-badge" v-if="user.steam_info?.steam_level">({{ user.steam_info.steam_level }})</span>
                </div>
              </div>
              <div class="check-item">
                <label for="own_rust">Owns Rust:</label>
                <input type="checkbox" id="own_rust" v-model="own_rust" @change="updateUser('steam_info.own_rust', own_rust)" />
              </div>
              <Button @click="refetchSteamChecks" class="refresh-btn" label="Reload" background-color="rgba(51, 142, 255, 1)" />
            </div>
          </div>
          
          <div class="card-grid">
            <div class="card">
              <UserBetStats :user="user" @updateUser="updateUser" />
            </div>
            <div class="card">
              <UserVault :vault="vault" @updateVault="updateVault" />
            </div>
          </div>
          
          <div class="card-grid">
            <div class="card">
              <AffiliateInfo :user="user" />
            </div>
            <div class="card">
              <Punishments :user="user" @updateUser="updateUser" @reloadUser="loadUser" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Offers Section -->
    <section class="panel offers-panel">
      <h2 class="panel-title">Offers</h2>
      <div class="panel-content">
        <div class="card">
          <OffersMenu @updateOffers="updateOffers" :user="user" :offers="offers" :offerCount="offerCount" ref="offers" />
        </div>
        
        <div class="card">
          <Offers 
            :offers="offers" 
            :offer-count="offerCount" 
            :offers-type="offersType" 
            :selected-offer="selectedOffer" 
            @selectOffer="selectOffer" 
          />
        </div>
        
        <div v-if="selectedOffer" class="card">
          <Offer 
            :offers-type="offersType" 
            :selected-offer="selectedOffer" 
            @updateSelectedOffer="updateSelectedOffer" 
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import Search from '@/components/Users/User/Search'
import UserInfo from '@/components/Users/User/UserInfo'
import UserStats from '@/components/Users/User/UserStats'
import AffiliateInfo from '@/components/Users/User/AffiliateInfo'
import Punishments from '@/components/Users/User/Punishments'
import { useToast } from 'vue-toastification'
import UserBetStats from '@/components/Users/User/UserBetStats'
import UserVault from '@/components/Users/User/UserVault'
// import UserPromotion from '@/components/Users/User/UserPromotion'
// import BackpackItem from '@/components/Users/Backpack/Item'
// import BackpackSettings from '@/components/Users/Backpack/Settings'
import _ from 'lodash'
import OffersMenu from '@/components/Users/Offers/OffersMenu'
import Offers from '@/components/Users/Offers/Offers'
import Offer from '@/components/Users/Offers/Offer'
import Button from '@/components/Button'

export default {
  name: 'Users',
  components: {
    Button,
    Offer,
    Offers,
    OffersMenu,
    // BackpackSettings,
    // BackpackItem,
    // UserPromotion,
    UserBetStats,
    Punishments,
    AffiliateInfo,
    UserStats,
    UserInfo,
    Search,
    UserVault
  },

  data() {
    return {
      user: undefined,
      vault: undefined,
      steam_level: { type: Boolean, default: false },
      own_rust: { type: Boolean, default: false },
      backpack: [],
      offers: [],
      offerCount: 0,
      offersType: 'steam',
      selectedOffer: undefined,
    }
  },

  setup() {
    const toast = useToast();
    return {toast}
  },

  methods: {
    async loadUser(steamID) {
      this.user = undefined
      this.backpack = []
      this.offers = []
      this.selectedOffer = undefined
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/user/" + steamID, {
        credentials: 'include',
      })
      let response = await res.json();
      if (!response.success) {
        this.toast.error(response.message);
      } else {
        this.user = response.data

        if (this.user.steam_info && this.user.steam_info.steam_level && this.user.steam_info.steam_level >= 5) {
          this.steam_level = true
        }

        if (this.user.steam_info && this.user.steam_info.own_rust) {
          this.own_rust = true
        }
        await this.$router.push({ path: '/users/' + this.user.steamID })

        await this.loadVault(this.user.steamID)
      }
    },

    async loadVault(steamID) {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/user/vault/" + steamID, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      let response = await res.json();
      if (!response.success) {
        this.toast.error(response.message);
      } else {
        this.vault = response.data
      }
    },

    async updateVault(balance) {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/user/vault/update", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ steamID: this.user.steamID, balance: balance })
      })
      let response = await res.json();
      if (!response.success) {
        this.toast.error(response.message);
      } else {
        this.toast.success('Success: Vault updated!')
      }
    },
    
    

    async updateUser(row, value) {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/user/update", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({steamID: this.user.steamID, row, value})
      })
      let response = await res.json();
      if (!response.success) {
        this.toast.error(response.message);
      } else {
        if(response.data.updated) {
          this.user = response.data.user
          this.toast.success('Success: User updated!')
        }
      }
    },

    async refetchSteamChecks() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + `/user/steamCheck/${this.user.steamID}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })

      let response = await res.json();

      if (!response.success) {
        this.toast.error(response.message);

      } else {
        console.log(response)

        this.steam_level = response.data.steam_level >= 5
        this.own_rust = response.data.own_rust

        this.toast.success('Success: Steam checks re-fetched!')
      }
    },

    async loadBackpack() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/user/backpack/" + this.user.steamID, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      let response = await res.json();
      if (!response.success) {
        this.toast.error(response.message);
      } else {
        if(response.data) {
          this.backpack = _.sortBy(response.data, (item) => {return -item.price})
        }
      }
    },

    async reloadOffers() {
      await this.$refs.offers.loadOffers()
    },

    updateSelectedOffer(offer) {
      this.selectedOffer = offer
      let i = _.findIndex(this.offers, { _id: offer._id })
      this.offers[i] = offer
    },

    updateOffers(object) {
      this.offers = object.data.data
      this.offerCount = object.data.totalCount
      this.offersType = object.type
      this.selectedOffer = undefined
    },

    selectOffer(offer) {
      this.selectedOffer = offer
    },

    async updateItems(data) {
      let row = data.row
      let value = data.value === true ? 'true' : 'false'
      let items = data.items || _.filter(this.backpack, item => item.selected)
      items = _.map(items, item => item.uuid)

      if(_.isEmpty(items)) {
        this.toast.error('Error: No items selected!')
        return
      }

      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/user/updateItems", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ steamID: this.user.steamID, row, value, uuids: items })
      })
      let response = await res.json();
      if (!response.success) {
        this.toast.error(response.message);
      } else {
        if (response.data.success) {
          this.backpack = _.map(this.backpack, item => {
            if (_.includes(response.data.uuids, item.uuid)) item[row] = data.value
            return item
          })
          this.toast.success('Success: Items updated!')
        }
      }
    },

    async removeItems() {
      let items = _.filter(this.backpack, item => item.selected)
      items = _.map(items, item => item.uuid)

      if(_.isEmpty(items)) {
        this.toast.error('Error: No items selected!')
        return
      }

      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/user/removeItems", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ steamID: this.user.steamID, uuids: items })
      })
      let response = await res.json();
      if (!response.success) {
        this.toast.error(response.message);
      } else {
        if (response.data.success) {
          console.log(response.data)
          this.backpack = _.filter(this.backpack, item => !_.includes(response.data.uuids, item.uuid))
          this.toast.success('Success: Items removed!')
        }
      }
    },
  }
}
</script>

<style scoped>
.users-container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Include padding in width calculation */
}

@media (min-width: 768px) {
  .users-container {
    padding: 2rem;
  }
}

@media (min-width: 1024px) {
  .users-container {
    flex-direction: row;
    align-items: flex-start;
    height: calc(100vh - 6rem); /* Account for header and some padding */
    overflow-y: auto;
  }
}

.panel {
  background-color: #232830;
  border-radius: 0.75rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%; /* Ensure panel doesn't exceed viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling in panels */
  box-sizing: border-box; /* Include padding in width calculation */
}

@media (min-width: 1024px) {
  .panel {
    width: 50%;
    max-height: 100%;
    overflow-y: auto;
  }
}

.panel-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
  padding: 1.25rem;
  background-color: #1a1f25;
  margin: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
}

.panel-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  box-sizing: border-box; /* Include padding in width calculation */
}

@media (min-width: 768px) {
  .panel-content {
    padding: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .panel-content {
    overflow-y: auto;
  }
}

.search-container {
  margin-bottom: 0.5rem;
}

.card {
  background-color: #1E242B;
  border-radius: 0.75rem;
  padding: 1.25rem;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.user-profile-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.trade-url {
  display: block;
  text-decoration: none;
  color: #338EFF;
  word-break: break-all;
  font-size: 0.875rem;
  transition: color 0.2s ease;
}

.trade-url:hover {
  color: #5EA5FF;
  text-decoration: underline;
}

.steam-checks {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 768px) {
  .steam-checks {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.check-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.level-badge {
  background-color: #338EFF;
  color: white;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
}

.refresh-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.refresh-btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  border: 2px solid #555;
  background-color: #1a1f25;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

input[type="checkbox"]:checked {
  background-color: #338EFF;
  border-color: #338EFF;
}

input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 0.35rem;
  top: 0.15rem;
  width: 0.3rem;
  height: 0.6rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
