<template>
  <!-- Dots loader (default) -->
  <div v-if="type === 'dots'" class="loader-dots" :style="sizeStyles">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  
  <!-- Spinner loader -->
  <div v-else-if="type === 'spinner'" class="loader-spinner" :style="sizeStyles">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  
  <!-- Pulse loader -->
  <div v-else-if="type === 'pulse'" class="loader-pulse" :style="sizeStyles"></div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    type: {
      type: String,
      default: 'dots',
      validator: (value) => ['dots', 'spinner', 'pulse'].includes(value)
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium', 'large'].includes(value)
    },
    color: {
      type: String,
      default: '#338EFF'
    }
  },
  computed: {
    sizeStyles() {
      const sizes = {
        small: { width: '40px', height: '40px', fontSize: '8px' },
        medium: { width: '60px', height: '60px', fontSize: '10px' },
        large: { width: '80px', height: '80px', fontSize: '13px' }
      };
      
      return {
        ...sizes[this.size],
        '--loader-color': this.color
      };
    }
  }
}
</script>

<style scoped>
/* Base styles for loaders */
.loader-dots, .loader-spinner, .loader-pulse {
  display: inline-block;
  position: relative;
}

/* Dots loader */
.loader-dots div {
  position: absolute;
  width: calc(var(--loader-color-size, 13px));
  height: calc(var(--loader-color-size, 13px));
  border-radius: 50%;
  background: var(--loader-color, #338EFF);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader-dots div:nth-child(1) {
  left: calc(var(--loader-color-size, 8px));
  animation: loader-dots1 0.6s infinite;
}

.loader-dots div:nth-child(2) {
  left: calc(var(--loader-color-size, 8px));
  animation: loader-dots2 0.6s infinite;
}

.loader-dots div:nth-child(3) {
  left: calc(var(--loader-color-size, 32px));
  animation: loader-dots2 0.6s infinite;
}

.loader-dots div:nth-child(4) {
  left: calc(var(--loader-color-size, 56px));
  animation: loader-dots3 0.6s infinite;
}

@keyframes loader-dots1 {
  0% { transform: scale(0); }
  100% { transform: scale(1); }
}

@keyframes loader-dots3 {
  0% { transform: scale(1); }
  100% { transform: scale(0); }
}

@keyframes loader-dots2 {
  0% { transform: translate(0, 0); }
  100% { transform: translate(24px, 0); }
}

/* Spinner loader */
.loader-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80%;
  height: 80%;
  margin: 10%;
  border: 3px solid transparent;
  border-radius: 50%;
  animation: loader-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--loader-color, #338EFF) transparent transparent transparent;
}

.loader-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader-spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Pulse loader */
.loader-pulse {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--loader-color, #338EFF);
  opacity: 0.6;
  animation: loader-pulse 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes loader-pulse {
  0%, 100% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 0.2;
  }
}
</style>
