<template>
  <header v-if="user" class="header">
    <div class="header-container">
      <div class="brand">
        <span class="brand-name">CSGOStake Admin</span>
      </div>
      
      <div class="menu-toggle" @click="menuOpen = !menuOpen">
        <i class="fas" :class="menuOpen ? 'fa-times' : 'fa-bars'"></i>
      </div>
      
      <nav class="menu" :class="{ 'menu-open': menuOpen }">
        <router-link class="menu-item" to="/" @click="menuOpen = false">Home</router-link>
        <router-link v-if="user.permissions && user.permissions.view_history" class="menu-item" to="/history" @click="menuOpen = false">History</router-link>
        <router-link v-if="user.permissions && user.permissions.access_stats" class="menu-item" to="/stats" @click="menuOpen = false">Stats</router-link>
        <router-link v-if="user.permissions && user.permissions.access_pushing" class="menu-item" to="/storage" @click="menuOpen = false">Storage</router-link>
        <router-link v-if="user.permissions && user.permissions.access_finance" class="menu-item" to="/finance" @click="menuOpen = false">Finance</router-link>
        <router-link v-if="user.permissions && user.permissions.access_affiliates" class="menu-item" to="/affiliates" @click="menuOpen = false">Affiliates</router-link>
        <router-link v-if="user.permissions && (user.permissions.access_users || user.permissions.access_users_limited)" class="menu-item" to="/users" @click="menuOpen = false">Users</router-link>
        <router-link v-if="user.permissions && user.permissions.access_prices" class="menu-item" to="/prices" @click="menuOpen = false">Prices</router-link>
      </nav>
      
      <div class="user-section">
        <div class="user-details">
          <img class="avatar" :src="user.avatarfull" alt="avatar"/>
          <span class="username">{{ user.personaname }}</span>
        </div>
        <a class="logout-link" :href="logout">
          <Button class="logout-btn" label="Logout" size="small" background-color="rgba(200, 39, 33, 1)" />
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "Header",
  components: {
    Button
  },
  props: {
    user: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      menuOpen: false
    }
  },
  computed: {
    logout() {
      return (process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/logout";
    }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1E242B;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  max-width: 1600px;
  margin: 0 auto;
}

.brand {
  display: flex;
  align-items: center;
}

.brand-name {
  font-weight: 600;
  font-size: 1.1rem;
  color: white;
  display: none;
}

.menu-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
}

.menu-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu {
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.menu-item {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border-radius: 0.375rem;
}

.menu-item:hover, .menu-item.router-link-active {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

.user-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-details {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid rgba(51, 142, 255, 0.5);
  object-fit: cover;
}

.username {
  color: white;
  font-size: 0.95rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.logout-link {
  text-decoration: none;
}

.logout-btn {
  height: 2.25rem;
  min-width: 5rem;
}

@media (max-width: 1024px) {
  .brand-name {
    display: block;
  }
  
  .menu {
    position: fixed;
    top: 4rem;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #1E242B;
    flex-direction: column;
    overflow: hidden;
    transition: height 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    z-index: 101;
  }
  
  .menu-open {
    height: auto;
    padding: 0.75rem 0;
  }
  
  .menu-item {
    width: 100%;
    height: auto;
    padding: 0.75rem 1.5rem !important;
    justify-content: flex-start;
    border-radius: 0;
    border-left: 3px solid transparent;
    margin: 0 !important;
    box-sizing: border-box;
  }
  
  .menu-item:hover, .menu-item.router-link-active {
    background-color: rgba(255, 255, 255, 0.05);
    border-left-color: #338EFF;
  }
  
  .menu-item + .menu-item {
    margin-top: 0.25rem;
  }
  
  .username {
    display: none;
  }
}

@media (min-width: 1025px) {
  .menu-toggle {
    display: none;
  }
}
</style>
