<template>
  <div v-if="user.punishments" class="punishments-container">
    <div class="punishment-card">
      <div class="punishment-info">
        <div class="punishment-header">
          <div class="status-label">Banned: 
            <span :class="user.punishments.banned ? 'status-active' : 'status-inactive'">
              {{ user.punishments.banned ? 'Yes' : 'No' }}
            </span>
          </div>
          <div class="until-date" v-if="user.punishments.banned && user.punishments.ban_end_date">
            Until: {{ new Date(user.punishments.ban_end_date).toLocaleDateString("en-US") }}
            {{ new Date(user.punishments.ban_end_date).toLocaleTimeString("en-US") }}
          </div>
        </div>
      </div>
      <div class="punishment-actions">
        <input 
          class="reason-input" 
          type="text" 
          v-model="ban_reason" 
          placeholder="Enter a reason"
        >
        <Button 
          class="action-button" 
          :label="user.punishments.banned ? 'Unban' : 'Ban'" 
          :background-color="user.punishments.banned ? 'rgba(51, 142, 255, 1)' : 'rgba(200, 39, 33, 1)'" 
          @click="swapPunishment('ban')" 
        />
      </div>
    </div>
    
    <div class="punishment-card">
      <div class="punishment-info">
        <div class="punishment-header">
          <div class="status-label">Muted: 
            <span :class="user.punishments.muted ? 'status-active' : 'status-inactive'">
              {{ user.punishments.muted ? 'Yes' : 'No' }}
            </span>
          </div>
          <div class="until-date" v-if="user.punishments.muted && user.punishments.mute_end_date">
            Until: {{ new Date(user.punishments.mute_end_date).toLocaleDateString("en-US") }}
            {{ new Date(user.punishments.mute_end_date).toLocaleTimeString("en-US") }}
          </div>
        </div>
      </div>
      <div class="punishment-actions">
        <input 
          class="reason-input" 
          type="text" 
          v-model="mute_reason" 
          placeholder="Enter a reason"
        >
        <Button 
          class="action-button" 
          :label="user.punishments.muted ? 'Unmute' : 'Mute'" 
          :background-color="user.punishments.muted ? 'rgba(51, 142, 255, 1)' : 'rgba(200, 39, 33, 1)'" 
          @click="swapPunishment('mute')" 
        />
      </div>
    </div>
    
    <div class="punishment-card withdraw-block">
      <div class="punishment-info">
        <div class="status-label">Withdraw blocked: 
          <span :class="user.withdraw_blocked ? 'status-active' : 'status-inactive'">
            {{ user.withdraw_blocked ? 'Yes' : 'No' }}
          </span>
        </div>
      </div>
      <Button 
        @click="withdrawBlock" 
        class="block-button" 
        :label="user.withdraw_blocked ? 'Unblock' : 'Block'" 
        :background-color="user.withdraw_blocked ? 'rgba(51, 142, 255, 1)' : 'rgba(200, 39, 33, 1)'" 
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import { useToast } from 'vue-toastification'
export default {
  name: 'Punishments',
  components: { Button },
  emits: ['updateUser', 'reloadUser'],
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      banned: {type: Boolean},
      ban_reason: {type: String},
      muted: {type: Boolean},
      mute_reason: {type: String}
    }
  },
  setup() {
    const toast = useToast();
    return {toast}
  },
  mounted() {
    this.ban_reason = this.user.punishments.banned ? this.user.punishments.ban_reason || '' : ''
    this.mute_reason = this.user.punishments.muted ? this.user.punishments.mute_reason || '' : ''
  },
  methods: {
    async swapPunishment(type) {
      if ((this.user.punishments.banned && type === 'ban') || (this.user.punishments.muted && type === 'mute')) {
        if(type === 'ban') this.$emit('updateUser', 'punishments.banned', false)
        if(type === 'mute') this.$emit('updateUser', 'punishments.muted', false)
      } else {
        const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/user/" + type, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ steamID: this.user.steamID, reason: this.ban_reason })
        })
        let response = await res.json();
        if (!response.success) {
          this.toast.error(response.message);
        } else {
          if (response.data.success || response.data.updated) {
            this.toast.success('Success: User punished!')
            this.$emit('reloadUser', this.user.steamID)
          }
        }
      }
    },
    withdrawBlock() {
      this.$emit('updateUser', 'withdraw_blocked', !this.user.withdraw_blocked)
    }
  }
}
</script>

<style scoped>
.punishments-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.punishment-card {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: rgba(26, 31, 37, 0.5);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.punishment-card:hover {
  background-color: rgba(26, 31, 37, 0.7);
}

.punishment-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.punishment-header {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

@media (min-width: 640px) {
  .punishment-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.status-label {
  font-weight: 500;
  font-size: 0.95rem;
}

.status-active {
  color: #F83C63;
  font-weight: 600;
}

.status-inactive {
  color: #41FF68;
  font-weight: 600;
}

.until-date {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
}

.punishment-actions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

@media (min-width: 640px) {
  .punishment-actions {
    flex-direction: row;
    align-items: center;
  }
}

.reason-input {
  flex: 1;
  padding: 0.625rem;
  background-color: #1a1f25;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.375rem;
  color: white;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.reason-input:focus {
  outline: none;
  border-color: rgba(51, 142, 255, 0.5);
  box-shadow: 0 0 0 2px rgba(51, 142, 255, 0.25);
}

.action-button {
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

@media (max-width: 639px) {
  .action-button {
    width: 100%;
  }
}

@media (min-width: 640px) {
  .action-button {
    width: 30%;
  }
}

.withdraw-block {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

@media (min-width: 640px) {
  .withdraw-block {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.block-button {
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

@media (max-width: 639px) {
  .block-button {
    width: 100%;
  }
}

@media (min-width: 640px) {
  .block-button {
    width: 30%;
  }
}
</style>