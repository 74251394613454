<template>
  <div v-if="user.affiliate" class="affiliate-info">
    <div v-if="user.affiliate.code_used" class="affiliate-code">
      <span class="affiliate-label">Claimed code:</span>
      <span class="code-value">{{ user.affiliate.code_used }}</span>
    </div>
    
    <div v-if="user.affiliate.case_opened" class="case-info">
      <div class="case-text">
        <div class="case-label">Opened from case:</div>
        <div v-if="user.affiliate.case_opened.item" class="case-item-name">
          {{ user.affiliate.case_opened.item.name }}
        </div>
      </div>
      <img 
        v-if="user.affiliate.case_opened?.item?.icon" 
        class="case-item-image" 
        :src="'http://cdn.steamcommunity.com/economy/image/' + user.affiliate.case_opened.item.icon" 
        alt="case item"
      />
    </div>
    
    <div class="affiliate-stats">
      <div class="stat-card">
        <span class="stat-label">Commission</span>
        <span class="stat-value">${{ user.affiliate.commission.toFixed(2) }}</span>
      </div>
      
      <div class="stat-card">
        <span class="stat-label">Wagered</span>
        <span class="stat-value">${{ user.affiliate.wagered.toFixed(2) }}</span>
      </div>
      
      <div class="stat-card">
        <span class="stat-label">Withdrawn</span>
        <span class="stat-value">${{ user.affiliate.withdraw.toFixed(2) }}</span>
      </div>
      
      <div class="stat-card">
        <span class="stat-label">Deposited</span>
        <span class="stat-value">${{ user.affiliate.deposited.toFixed(2) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AffiliateInfo',
  props: {
    user: {
      type: Object,
    },
  },
}
</script>

<style scoped>
.affiliate-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.affiliate-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  background-color: rgba(26, 31, 37, 0.5);
  padding: 0.75rem;
  border-radius: 0.5rem;
}

.affiliate-label {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
}

.code-value {
  font-weight: 600;
  font-size: 1.2rem;
  color: #338EFF;
}

.case-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(26, 31, 37, 0.5);
  padding: 0.75rem;
  border-radius: 0.5rem;
  gap: 1rem;
}

.case-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.case-label {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
}

.case-item-name {
  font-weight: 500;
  font-size: 0.95rem;
  color: white;
}

.case-item-image {
  height: 4rem;
  border-radius: 0.25rem;
  object-fit: contain;
  transition: transform 0.2s ease;
}

.case-item-image:hover {
  transform: scale(1.1);
}

.affiliate-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
}

@media (min-width: 768px) {
  .affiliate-stats {
    grid-template-columns: repeat(4, 1fr);
  }
}

.stat-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  background-color: rgba(26, 31, 37, 0.5);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.stat-card:hover {
  background-color: rgba(26, 31, 37, 0.8);
  transform: translateY(-2px);
}

.stat-label {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.25rem;
}

.stat-value {
  font-size: 1rem;
  font-weight: 600;
  color: #338EFF;
}
</style>