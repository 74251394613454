<template>
  <div class="user-stats">
    <div class="stat-grid">
      <div class="stat-item">
        <div class="stat-label">Last Login</div>
        <div class="stat-value">{{ user.user_stats?.last_login ? user.user_stats.last_login.substr(0, user.user_stats.last_login.indexOf(',')) : 'N/A' }}</div>
      </div>
      
      <div class="stat-item">
        <div class="stat-label">Last OS</div>
        <div class="stat-value">{{ user.user_stats?.last_os || 'N/A' }}</div>
      </div>
      
      <div class="stat-item">
        <div class="stat-label">Browser</div>
        <div class="stat-value">{{ user.user_stats?.browser || 'N/A' }}</div>
      </div>
      
      <div class="stat-item">
        <div class="stat-label">Last IP</div>
        <div class="stat-value">{{ user.user_stats?.last_ip || 'N/A' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserStats',
  props: {
    user: {
      type: Object,
    },
  },
}
</script>

<style scoped>
.user-stats {
  width: 100%;
}

.stat-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.75rem;
}

@media (min-width: 480px) {
  .stat-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.stat-item {
  padding: 0.75rem;
  background-color: rgba(26, 31, 37, 0.5);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.stat-item:hover {
  background-color: rgba(26, 31, 37, 0.8);
  transform: translateY(-2px);
}

.stat-label {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0.25rem;
}

.stat-value {
  font-size: 0.95rem;
  font-weight: 500;
  color: white;
  word-break: break-word;
}
</style>