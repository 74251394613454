<template>
  <div class="offers-list-container">
    <!-- Deposits/Withdrawals Offers -->
    <div v-if="offersType === `depositwd`" class="offers-list">
      <div
        v-for="offer in offers"
        :key="offer._id"
        @click="$emit(`selectOffer`, offer)"
        :class="['offer-item', selectedOffer && offer._id === selectedOffer._id ? 'selected' : '']"
      >
        <div class="offer-type">{{ offer.type }}</div>
        <div class="offer-date">{{ new Date(offer.date).toLocaleString() }}</div>
        <div class="offer-game-type">
          <span v-if="offer.game_type">{{ offer.game_type }}</span>
          <span v-else class="empty-value">-</span>
        </div>
        <div v-if="offer.price" class="offer-price">${{ offer.price.toFixed(2) }}</div>
      </div>
      
      <div v-if="offers.length === 0" class="no-offers-message">
        No offers found
      </div>
    </div>

    <!-- Transactions Offers -->
    <div v-else-if="offersType === `transactions`" class="offers-list">
      <div
        v-for="offer in offers"
        :key="offer._id"
        @click="$emit(`selectOffer`, offer)"
        :class="['offer-item', selectedOffer && offer._id === selectedOffer._id ? 'selected' : '']"
      >
        <div class="offer-type">{{ offer.type }}</div>
        <div class="offer-date">{{ new Date(offer.date).toLocaleString() }}</div>
        <div class="offer-game-type">
          <span v-if="offer.game_type">{{ offer.game_type }}</span>
          <span v-else class="empty-value">-</span>
        </div>
        <div v-if="offer.price" class="offer-price">${{ offer.price.toFixed(2) }}</div>
      </div>
      
      <div v-if="offers.length === 0" class="no-offers-message">
        No transactions found
      </div>
    </div>

    <!-- Games Offers -->
    <div v-else-if="offersType === `games`" class="offers-list">
      <div
        v-for="offer in offers"
        :key="offer.id"
        @click="$emit(`selectOffer`, offer)"
        :class="['offer-item game', selectedOffer && offer.id === selectedOffer.id ? 'selected' : '']"
      >
        <div class="offer-type">{{ offer._id }}</div>
        <div class="offer-date">{{ new Date(offer.date).toLocaleString() }}</div>
        <div class="offer-id">{{ offer.id }}</div>
      </div>
      
      <div v-if="offers.length === 0" class="no-offers-message">
        No games found
      </div>
    </div>

    <!-- Balance Offers -->
    <div v-else-if="offersType === `balance`" class="offers-list">
      <div
        v-for="offer in offers"
        :key="offer._id"
        @click="$emit(`selectOffer`, offer)"
        :class="['offer-item', selectedOffer && offer._id === selectedOffer._id ? 'selected' : '']"
      >
        <div class="offer-type">Balance Update</div>
        <div class="offer-date">{{ new Date(offer.date).toLocaleString() }}</div>
        <div class="offer-balance">${{ offer.balance.toFixed(2) }}</div>
        <div class="offer-change" :class="offer.change > 0 ? 'positive' : 'negative'">
          {{ offer.change > 0 ? '+' : '-' }}${{ Math.abs(offer.change).toFixed(2) }}
        </div>
      </div>
      
      <div v-if="offers.length === 0" class="no-offers-message">
        No balance updates found
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Offers',
    emits: ['selectOffer'],

    /* -------- PROPS -------- */
    props: {
      offers: {
        type: Array,
        default: () => []
      },

      offerCount: {
        type: Number,
        default: 0
      },

      offersType: {
        type: String,
        default: 'steam'
      },

      selectedOffer: {
        type: Object,
        default: undefined
      }
    }
  }
</script>

<style scoped>
.offers-list-container {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0.5rem;
  background-color: rgba(26, 31, 37, 0.3);
}

@media (min-width: 768px) {
  .offers-list-container {
    max-height: 26rem;
  }
}

.offers-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.offer-item {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0.5rem;
  background-color: rgba(21, 23, 27, 0.9);
  border-radius: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  overflow: hidden;
}

@media (min-width: 640px) {
  .offer-item {
    grid-template-columns: 1fr 1.2fr 0.8fr 1fr;
    grid-template-rows: auto;
  }
}

.offer-item.game {
  grid-template-columns: 1fr 1fr;
}

@media (min-width: 640px) {
  .offer-item.game {
    grid-template-columns: 1fr 2fr 1fr;
  }
}

.offer-item:hover {
  background-color: rgba(21, 23, 27, 0.7);
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.offer-item.selected {
  border-color: rgba(51, 142, 255, 1);
  background-color: rgba(51, 142, 255, 0.1);
}

.offer-type {
  font-weight: 500;
  font-size: 0.9rem;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.offer-date {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

@media (min-width: 640px) {
  .offer-date {
    text-align: left;
  }
}

.offer-game-type, .offer-id {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.offer-price, .offer-balance {
  font-weight: 600;
  color: #338EFF;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.offer-change {
  font-weight: 600;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.offer-change.positive {
  color: #41FF68;
}

.offer-change.negative {
  color: #F83C63;
}

.empty-value {
  color: rgba(255, 255, 255, 0.4);
}

.no-offers-message {
  padding: 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
}

/* Custom scrollbar for the offers list */
.offers-list-container::-webkit-scrollbar {
  width: 8px;
}

.offers-list-container::-webkit-scrollbar-track {
  background: rgba(21, 23, 27, 0.5);
  border-radius: 4px;
}

.offers-list-container::-webkit-scrollbar-thumb {
  background: rgba(51, 142, 255, 0.5);
  border-radius: 4px;
}

.offers-list-container::-webkit-scrollbar-thumb:hover {
  background: rgba(51, 142, 255, 0.8);
}
</style>
