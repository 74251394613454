<template>
  <div v-if="user" class="user-bet-stats">
    <div class="stat-item">
      <div class="stat-header">
        <span class="stat-label">XP</span>
        <div class="stat-input-wrapper">
          <input 
            id="xp" 
            type="number" 
            placeholder="Enter XP..." 
            v-model="xp" 
            @change="updateXP" 
            class="stat-input"
          />
        </div>
      </div>
    </div>
    
    <div class="financial-stats">
      <div class="stat-card">
        <span class="stat-name">Deposited</span>
        <span v-if="user.profit" class="stat-amount deposited">${{ user.profit.deposited.toFixed(2) }}</span>
      </div>
      <div class="stat-card">
        <span class="stat-name">Withdrawn</span>
        <span v-if="user.profit" class="stat-amount withdrawn">${{ user.profit.withdraw.toFixed(2) }}</span>
      </div>
    </div>
    
    <div class="stat-item">
      <div class="stat-header">
        <span class="stat-label">Balance</span>
        <div class="stat-input-wrapper">
          <input 
            id="balance" 
            type="number" 
            placeholder="Enter Balance..." 
            v-model="balance" 
            @change="updateBalance" 
            class="stat-input"
          />
        </div>
      </div>
    </div>
    
    <div class="stat-item">
      <div class="stat-header">
        <span class="stat-label">Wager Requirement</span>
        <div class="stat-input-wrapper">
          <input 
            id="wager" 
            type="number" 
            placeholder="Wager requirement..." 
            v-model="wager" 
            @change="updateWager" 
            class="stat-input"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserBetStats',
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      xp: { type: Number, default: 0 },
      balance: { type: Number, default: 0 },
      wager: { type: Number, default: 0 },
    }
  },
  watch: {
    user: {
      handler: function (val) {
        this.xp = val.xp;
        this.balance = val.balance;
        this.wager = val.wager_requirement;
      },
      deep: true,
    },
  },
  mounted() {
    this.xp = this.user.xp
    this.balance = this.user.balance
    this.wager = this.user.wager_requirement
  },
  methods: {
    updateXP() {
      this.$emit('updateUser', 'xp', this.xp)
    },
    updateBalance() {
      this.$emit('updateUser', 'balance', this.balance)
    },
    updateWager() {
      this.$emit('updateUser', 'wager_requirement', this.wager)
    }
  }
}
</script>

<style scoped>
.user-bet-stats {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.stat-item {
  width: 100%;
}

.stat-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (min-width: 640px) {
  .stat-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.stat-label {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  white-space: nowrap;
  margin-right: 0.5rem;
}

.stat-input-wrapper {
  flex: 1;
  width: 100%;
  min-width: 0;
}

.stat-input {
  width: 100%;
  box-sizing: border-box;
  background-color: #1a1f25;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0.75rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.stat-input:focus {
  outline: none;
  border-color: rgba(51, 142, 255, 0.5);
  box-shadow: 0 0 0 2px rgba(51, 142, 255, 0.25);
}

.financial-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  margin: 0.5rem 0;
  padding: 0.75rem;
  background-color: rgba(45, 51, 60, 0.3);
  border-radius: 0.5rem;
}

.stat-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  background-color: rgba(26, 31, 37, 0.5);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.stat-card:hover {
  background-color: rgba(26, 31, 37, 0.8);
  transform: translateY(-2px);
}

.stat-name {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.25rem;
}

.stat-amount {
  font-size: 1.1rem;
  font-weight: 600;
}

.deposited {
  color: #41FF68;
}

.withdrawn {
  color: #F83C63;
}

* {
  box-sizing: border-box;
}
</style>