<template>
  <a :href="login" class="steam-login-button">
    <div class="login-content">
      <img src="../assets/steamlogin.svg" class="steam-icon" alt="Steam login" />
      <span class="login-text">Sign in with Steam</span>
    </div>
  </a>
</template>

<script>
require('dotenv').config()

export default {
  name: "LoginButton",
  computed:{
    login(){
      return (process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/auth/steam";
    }
  }
}
</script>

<style scoped>
.steam-login-button {
  display: inline-flex;
  text-decoration: none;
  border-radius: 0.5rem;
  overflow: hidden;
  background: linear-gradient(to right, #8ac329 0%, #4a7a16 100%);
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 15rem;
}

.steam-login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.steam-login-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  width: 100%;
  gap: 0.75rem;
}

.steam-icon {
  height: 1.5rem;
  width: auto;
}

.login-text {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  display: none;
}

@media (min-width: 480px) {
  .login-text {
    display: inline;
  }
}

@media (max-width: 479px) {
  .steam-login-button {
    width: auto;
  }
  
  .login-content {
    padding: 0.75rem;
  }
  
  .steam-icon {
    height: 2rem;
  }
}
</style>

