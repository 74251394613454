<template>
  <div v-if="vault" class="user-vault">
    <div class="vault-balance">
      <div class="balance-label">Vaulted:</div>
      <div class="balance-input-wrapper">
        <input 
          id="balance" 
          type="number" 
          placeholder="Enter Balance..." 
          v-model="balance" 
          @change="updateVault" 
          class="balance-input"
        />
      </div>
    </div>
    
    <div class="vault-stats">
      <div class="stat-card">
        <span class="stat-label">Total Added</span>
        <span class="stat-value added">${{ vault.stats.total_added.toFixed(2) }}</span>
      </div>
      
      <div class="stat-card">
        <span class="stat-label">Total Removed</span>
        <span class="stat-value removed">${{ vault.stats.total_removed.toFixed(2) }}</span>
      </div>
    </div>
    
    <div v-if="vault.lock.locked" class="vault-lock">
      <div class="lock-icon">
        <i class="fas fa-lock"></i>
      </div>
      <div class="lock-text">
        Locked until: {{ new Date(vault.lock.lock_end_date).toLocaleString() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserVault',
  data() {
    return {
      balance: { type: Number, default: 0 },
    }
  },

  mounted() {
    if (this.vault) {
      this.balance = this.vault.balance || 0
    }
  },

  watch: {
    vault: {
      handler: function (val) {
        this.balance = val.balance
      },
      deep: true
    }
  },
  props: {
    vault: {
      type: Object,
      required: true
    },
  },
  methods: {
    updateVault() {
      this.$emit('updateVault', this.balance)
    }
  }
}
</script>

<style scoped>
.user-vault {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.vault-balance {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (min-width: 640px) {
  .vault-balance {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.balance-label {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  white-space: nowrap;
  margin-right: 0.5rem;
}

.balance-input-wrapper {
  flex: 1;
  width: 100%;
  min-width: 0;
}

.balance-input {
  width: 100%;
  box-sizing: border-box;
  background-color: #1a1f25;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0.75rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.balance-input:focus {
  outline: none;
  border-color: rgba(51, 142, 255, 0.5);
  box-shadow: 0 0 0 2px rgba(51, 142, 255, 0.25);
}

.vault-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
}

.stat-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  background-color: rgba(26, 31, 37, 0.5);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.stat-card:hover {
  background-color: rgba(26, 31, 37, 0.8);
  transform: translateY(-2px);
}

.stat-label {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.25rem;
}

.stat-value {
  font-size: 1rem;
  font-weight: 600;
}

.added {
  color: #41FF68;
}

.removed {
  color: #F83C63;
}

.vault-lock {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: rgba(255, 165, 0, 0.15);
  border: 1px solid rgba(255, 165, 0, 0.3);
  border-radius: 0.5rem;
}

.lock-icon {
  color: #FFA500;
  font-size: 1.2rem;
}

.lock-text {
  font-size: 0.9rem;
  color: #FFA500;
}

* {
  box-sizing: border-box;
}
</style>