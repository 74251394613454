<template>
  <div class="offers-menu-container">
    <div class="offer-type-filters">
      <div class="filter-group">
        <Button
          @click="selectOffers(`depositwd`)"
          class="filter-btn"
          label="Deposits/Withdrawals"
          :background-color="selectedOffers === `depositwd` ? `rgba(51, 142, 255, 1)` : `rgba(21, 23, 27, 0.8)`"
        />
        <Button
          @click="selectOffers(`transactions`)"
          class="filter-btn"
          label="Transactions"
          :background-color="selectedOffers === `transactions` ? `rgba(51, 142, 255, 1)` : `rgba(21, 23, 27, 0.8)`"
        />
        <Button
          @click="selectOffers(`games`)"
          class="filter-btn"
          label="Games"
          :background-color="selectedOffers === `games` ? `rgba(51, 142, 255, 1)` : `rgba(21, 23, 27, 0.8)`"
        />
        <Button
          @click="selectOffers(`balance`)"
          class="filter-btn"
          label="Balance"
          :background-color="selectedOffers === `balance` ? `rgba(51, 142, 255, 1)` : `rgba(21, 23, 27, 0.8)`"
        />
      </div>
    </div>
    
    <div class="pagination-controls">
      <div class="pagination-wrapper">
        <v-pagination
          v-model="page"
          :pages="Math.floor(offerCount / perPage) + 1"
          :range-size="1"
          active-color="rgba(51, 142, 255, 1)"
          @update:modelValue="loadOffers"
        />
      </div>
      
      <div class="per-page-container">
        <label class="per-page-label">Per page:</label>
        <div class="per-page-options">
          <Button
            @click="changePerPage(10)"
            class="per-page-btn"
            label="10"
            :background-color="perPage === 10 ? `rgba(51, 142, 255, 1)` : `rgba(21, 23, 27, 0.8)`"
          />
          <Button
            @click="changePerPage(25)"
            class="per-page-btn"
            label="25"
            :background-color="perPage === 25 ? `rgba(51, 142, 255, 1)` : `rgba(21, 23, 27, 0.8)`"
          />
          <Button
            @click="changePerPage(50)"
            class="per-page-btn"
            label="50"
            :background-color="perPage === 50 ? `rgba(51, 142, 255, 1)` : `rgba(21, 23, 27, 0.8)`"
          />
          <Button
            @click="changePerPage(100)"
            class="per-page-btn"
            label="100"
            :background-color="perPage === 100 ? `rgba(51, 142, 255, 1)` : `rgba(21, 23, 27, 0.8)`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button'
  import VPagination from '@hennge/vue3-pagination'
  import _ from 'lodash'

  export default {
    name: 'OffersMenu',
    components: { Button, VPagination },
    emits: ['updateOffers'],

    /* -------- PROPS -------- */
    props: {
      user: {
        type: Object,
        default: null
      },

      offers: {
        type: Array,
        default: () => []
      },

      offerCount: {
        type: Number,
        default: 0
      }
    },

    /* -------- WATCH -------- */
    watch: {
      user: {
        async handler() {
          await this.loadOffers()
        },
        deep: true
      }
    },

    /* -------- DATA -------- */
    data() {
      return {
        selectedOffers: 'depositwd',
        page: 1,
        perPage: 10
      }
    },

    /* -------- MOUNTED -------- */
    async mounted() {
      if (this.user) {
        await this.loadOffers()
      }
    },

    /* -------- METHODS -------- */
    methods: {
      async selectOffers(type) {
        this.selectedOffers = type
        this.page = 1
        await this.loadOffers()
      },

      async changePerPage(perPage) {
        this.perPage = perPage
        await this.loadOffers()
      },

      async loadOffers() {
        if (!this.user) return
        
        const res = await fetch(
          (process.env.VUE_APP_MODE === 'production'
            ? process.env.VUE_APP_API_URL
            : process.env.VUE_APP_API_URL_DEV) +
            `/user/getOffers/${this.user.steamID}/${this.selectedOffers}/${this.page}/${this.perPage}`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )

        let response = await res.json()
        if (!response.success) {
          this.toast.error(response.message)
        } else {
          if (response.data) {
            if (_.isArray(response.data)) response.data = response.data[0]

            console.log(response.data)
            this.$emit('updateOffers', {
              type: this.selectedOffers,
              data: response.data
            })
          }
        }
      }
    }
  }
</script>

<style scoped>
.offers-menu-container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
}

.offer-type-filters {
  width: 100%;
}

.filter-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

@media (min-width: 768px) {
  .filter-group {
    grid-template-columns: repeat(4, 1fr);
  }
}

.filter-btn {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.filter-btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.pagination-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: rgba(26, 31, 37, 0.4);
  border-radius: 0.5rem;
  padding: 0.75rem;
}

@media (min-width: 768px) {
  .pagination-controls {
    flex-direction: row;
    justify-content: space-between;
  }
}

.per-page-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.per-page-label {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
}

.per-page-options {
  display: flex;
  gap: 0.375rem;
}

.per-page-btn {
  width: 2.5rem;
  height: 2rem;
  border-radius: 0.375rem;
  font-size: 0.85rem;
  transition: all 0.2s ease;
}

.per-page-btn:hover {
  opacity: 0.9;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
}

/* Override pagination styles */
:deep(.v-pagination-item) {
  padding: 0.375rem 0.75rem;
  margin: 0 0.25rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

:deep(.v-pagination-item.is-active) {
  background-color: rgba(51, 142, 255, 1);
  color: white;
}

:deep(.v-pagination-navigation-item) {
  padding: 0.375rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
}

:deep(.v-pagination-navigation-item:hover) {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
