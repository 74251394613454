<template>
  <!-- Deposit/Withdrawal Offer Details -->
  <div v-if="selectedOffer && offersType === `depositwd`" class="offer-details">
    <div class="offer-header">
      <div class="header-item">
        <span class="header-label">Type:</span>
        <span class="header-value">{{ selectedOffer.type }}</span>
      </div>
      <div class="header-item">
        <span class="header-label">Date:</span>
        <span class="header-value">{{ new Date(selectedOffer.date).toLocaleString() }}</span>
      </div>
      <div class="header-item">
        <span class="header-label">Game:</span>
        <span class="header-value">
          <span v-if="selectedOffer.game_type">{{ selectedOffer.game_type }}</span>
          <span v-else class="empty-value">-</span>
        </span>
      </div>
      <div v-if="selectedOffer.price" class="header-item price-container">
        <span class="price-amount">${{ selectedOffer.price.toFixed(2) }}</span>
        <div v-if="selectedOffer.type === 'Zen Deposit'" class="zen-actions">
          <button 
            @click="handleRefund"
            class="action-btn refund-btn"
            :disabled="!canRefund"
            :title="getRefundButtonTitle"
          >
            <i class="fas fa-undo-alt"></i>
            Refund
          </button>
          <button 
            @click="handleCancel"
            class="action-btn cancel-btn"
            :disabled="!canCancel"
            :title="getCancelButtonTitle"
          >
            <i class="fas fa-times"></i>
            Cancel
          </button>
        </div>
      </div>
    </div>
    
    <div
      v-if="selectedOffer.itemsList && selectedOffer.itemsList.length > 0"
      class="items-list">
      <div class="items-label">Items:</div>
      <div class="items-value">
        {{
          selectedOffer.itemsList
            .map((el) => el.name + `${el.amount ? ' x' + el.amount : ''}`)
            .join(', ')
        }}
      </div>
    </div>

    <div class="json-view">
      <vue-json-pretty
        :data="displayData"
        :deep="1"
        :showLength="true"
        :showLine="false"
        :showIcon="true"
        :virtual="true"
        :showDoubleQuotes="false" />
    </div>

    <div v-if="selectedOffer.type === 'Zen Deposit'" class="zen-info-section">
      <div class="zen-actions-notice">
        <i class="fas fa-info-circle"></i>
        Re-fetch transaction details to update available actions
      </div>
      <button 
        @click="fetchZenDetails" 
        class="zen-details-btn"
        :disabled="isLoadingZenDetails"
      >
        <i class="fas fa-info-circle"></i>
        {{ isLoadingZenDetails ? 'Loading...' : 'Update Invoice Data (via Zen)' }}
      </button>
    </div>
  </div>

  <!-- Transaction Offer Details -->
  <div v-if="selectedOffer && offersType === `transactions`" class="offer-details">
    <div class="offer-header">
      <div class="header-item">
        <span class="header-label">Type:</span>
        <span class="header-value">{{ selectedOffer.type }}</span>
      </div>
      <div class="header-item">
        <span class="header-label">Date:</span>
        <span class="header-value">{{ new Date(selectedOffer.date).toLocaleString() }}</span>
      </div>
      <div class="header-item">
        <span class="header-label">Game:</span>
        <span class="header-value">
          <span v-if="selectedOffer.game_type">{{ selectedOffer.game_type }}</span>
          <span v-else class="empty-value">-</span>
        </span>
      </div>
      <div v-if="selectedOffer.price" class="header-item">
        <span class="header-label">Price:</span>
        <span class="header-value price-amount">${{ selectedOffer.price.toFixed(2) }}</span>
      </div>
    </div>
    
    <div
      v-if="selectedOffer.itemsList && selectedOffer.itemsList.length > 0"
      class="items-list">
      <div class="items-label">Items:</div>
      <div class="items-value">
        {{
          selectedOffer.itemsList
            .map((el) => el.name + `${el.amount ? ' x' + el.amount : ''}`)
            .join(', ')
        }}
      </div>
    </div>

    <div class="json-view">
      <vue-json-pretty
        :data="selectedOffer"
        :deep="1"
        :showLength="true"
        :showLine="false"
        :showIcon="true"
        :virtual="true"
        :showDoubleQuotes="false" />
    </div>
  </div>

  <!-- Balance Offer Details -->
  <div v-if="selectedOffer && offersType === `balance`" class="offer-details">
    <div class="offer-header">
      <div class="header-item">
        <span class="header-label">Type:</span>
        <span class="header-value">Balance Update</span>
      </div>
      <div class="header-item">
        <span class="header-label">Date:</span>
        <span class="header-value">{{ new Date(selectedOffer.date).toLocaleString() }}</span>
      </div>
      <div class="header-item">
        <span class="header-label">Balance:</span>
        <span class="header-value">${{ selectedOffer.balance.toFixed(2) }}</span>
      </div>
      <div class="header-item">
        <span class="header-label">Change:</span>
        <span class="header-value" :class="selectedOffer.change > 0 ? 'positive-value' : 'negative-value'">
          {{ selectedOffer.change > 0 ? '+' : '-' }}${{ Math.abs(selectedOffer.change).toFixed(2) }}
        </span>
      </div>
    </div>
  </div>

  <!-- Games Offer Details -->
  <div v-if="selectedOffer && offersType === `games`" class="offer-details">
    <div class="json-view">
      <vue-json-pretty
        :data="selectedOffer"
        :deep="1"
        :showLength="true"
        :showLine="false"
        :showIcon="true"
        :virtual="true"
        :showDoubleQuotes="false" />
    </div>
  </div>
</template>

<script>
  import { useToast } from 'vue-toastification'
  import VueJsonPretty from 'vue-json-pretty'
  import './jsonstyle.css'

  export default {
    name: 'Offer',
    emits: ['updateSelectedOffer'],

    components: {
      VueJsonPretty
    },

    /* -------- SETUP -------- */
    setup() {
      const toast = useToast()
      return { toast }
    },

    /* -------- DATA -------- */
    data() {
      return {
        statuses: [
          'bot_pending',
          'bot_processing',
          'bot_sent',
          'bot_sent_checking',
          'bot_sent_received',
          'bot_invalid_items',
          'bot_error',
          'user_created',
          'user_pending',
          'user_processing',
          'user_active',
          'user_accepted',
          'user_invalid_items',
          'user_cannot_send',
          'user_invalid_url',
          'user_no_authenticator',
          'user_expired',
          'user_countered',
          'user_canceled',
          'user_declined',
          'user_canceled_by_second_factor'
        ],
        status: '',
        zenDetails: null,
        isLoadingZenDetails: false,
        showingZenDetails: false,
      }
    },

    computed: {
      displayData() {
        return this.showingZenDetails ? this.zenDetails : this.selectedOffer;
      },

      canRefund() {
        if (!this.selectedOffer.invoice_data?.actions) {
          return false; // Need to fetch data first
        }
        return this.selectedOffer.invoice_data.actions.refund;
      },

      canCancel() {
        if (!this.selectedOffer.invoice_data?.actions) {
          return false; // Need to fetch data first
        }
        return this.selectedOffer.invoice_data.actions.cancel;
      },

      getRefundButtonTitle() {
        if (!this.selectedOffer.invoice_data?.actions) {
          return 'Fetch transaction details to check if refund is available';
        }
        return this.canRefund ? 'Refund this transaction' : 'Refund not available for this transaction';
      },

      getCancelButtonTitle() {
        if (!this.selectedOffer.invoice_data?.actions) {
          return 'Fetch transaction details to check if cancellation is available';
        }
        return this.canCancel ? 'Cancel this transaction' : 'Cancellation not available for this transaction';
      }
    },

    /* -------- PROPS -------- */
    props: {
      offersType: {
        type: String,
        default: undefined
      },
      selectedOffer: {
        type: Object,
        default: undefined
      }
    },

    /* -------- WATCH -------- */
    watch: {
      selectedOffer: {
        async handler(newOffer) {
          this.status = newOffer.status
          this.zenDetails = null; // Reset zen details when offer changes
          this.showingZenDetails = false;
        },
        deep: true
      }
    },

    /* -------- MOUNTED -------- */
    mounted() {
      if (this.selectedOffer) {
        this.status = this.selectedOffer.status
      }
    },

    /* -------- METHODS -------- */
    methods: {
      updateStatus: async function () {
        const res = await fetch(
          (process.env.VUE_APP_MODE === 'production'
            ? process.env.VUE_APP_API_URL
            : process.env.VUE_APP_API_URL_DEV) + '/user/updateTransaction',
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: this.selectedOffer._id,
              row: 'status',
              value: this.status
            })
          }
        )
        let response = await res.json()
        if (!response.success) {
          this.toast.error(response.message)
        } else {
          if (response.data.updated) {
            this.$emit('updateSelectedOffer', response.data.offer)
            this.toast.success('Success: Status updated!')
          }
        }
      },

      handleRefund: async function() {
        if (!confirm('Are you sure you want to refund this Zen deposit?')) return;
        
        const res = await fetch(
          (process.env.VUE_APP_MODE === 'production'
            ? process.env.VUE_APP_API_URL
            : process.env.VUE_APP_API_URL_DEV) + '/user/zenRefund',
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              offerID: this.selectedOffer.offerID
            })
          }
        );
        
        const response = await res.json();
        if (!response.success) {
          this.toast.error(response.message);
        } else {
          this.toast.success('Successfully refunded Zen deposit!');
        }
      },

      async handleCancel() {
        if (!confirm('Are you sure you want to cancel this Zen deposit?')) return;
        
        try {
          const res = await fetch(
            `${process.env.VUE_APP_MODE === 'production'
              ? process.env.VUE_APP_API_URL
              : process.env.VUE_APP_API_URL_DEV}/user/zenCancel/${this.selectedOffer.offerID}`,
            {
              credentials: 'include',
            }
          );
          
          const response = await res.json();
          if (!response.success) {
            this.toast.error(response.message);
          } else {
            this.toast.success('Successfully cancelled Zen deposit!');
          }
        } catch (error) {
          this.toast.error('Error cancelling Zen deposit');
        }
      },
      
      async fetchZenDetails() {
        this.isLoadingZenDetails = true;
        try {
          const res = await fetch(
            `${process.env.VUE_APP_MODE === 'production'
              ? process.env.VUE_APP_API_URL
              : process.env.VUE_APP_API_URL_DEV}/user/zenTransaction/${this.selectedOffer.offerID}`,
            {
              credentials: 'include',
            }
          );
          
          const response = await res.json();
          if (!response.success) {
            this.toast.error(response.message);
          } else {
            this.zenDetails = response.data;
            this.showingZenDetails = true;
            
            // Instead of mutating prop directly, emit an event with updated offer
            if (response.data.actions) {
              const updatedOffer = {
                ...this.selectedOffer,
                invoice_data: {
                  ...this.zenDetails
                }
              };
              this.$emit('updateSelectedOffer', updatedOffer);
            }
          }
        } catch (error) {
          this.toast.error('Error fetching Zen transaction details');
        } finally {
          this.isLoadingZenDetails = false;
        }
      },
    }
  }
</script>

<style scoped>
.offer-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.offer-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  background-color: rgba(21, 23, 27, 0.8);
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .offer-header {
    grid-template-columns: repeat(4, 1fr);
  }
}

.header-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.header-label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
}

.header-value {
  font-size: 0.9rem;
  font-weight: 500;
  color: white;
  word-break: break-word;
}

.empty-value {
  color: rgba(255, 255, 255, 0.4);
}

.price-amount {
  color: #338EFF;
  font-weight: 600;
  font-size: 1rem;
}

.positive-value {
  color: #41FF68;
}

.negative-value {
  color: #F83C63;
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: rgba(21, 23, 27, 0.8);
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .items-list {
    flex-direction: row;
    align-items: center;
  }
}

.items-label {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 0.5rem;
  font-weight: 500;
  white-space: nowrap;
}

.items-value {
  font-size: 0.9rem;
  line-height: 1.4;
  color: white;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

.json-view {
  background-color: rgba(21, 23, 27, 0.8);
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

/* Add this to override JSON viewer styles to prevent horizontal overflow */
:deep(.vjs-tree) {
  overflow-x: auto;
  word-break: break-word;
}

:deep(.vjs-value) {
  word-break: break-word;
  overflow-wrap: break-word;
}

.zen-info-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.zen-actions-notice {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: rgba(140, 158, 255, 0.1);
  border-radius: 0.5rem;
  font-size: 0.85rem;
  color: #8c9eff;
}

.zen-actions-notice i {
  font-size: 1rem;
}

.zen-details-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #2c3e50;
  border: none;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  align-self: flex-end;
}

.zen-details-btn:hover:not(:disabled) {
  background-color: #34495e;
  transform: translateY(-1px);
}

.zen-details-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.zen-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .zen-actions {
    margin-top: 0.75rem;
  }
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  color: white;
}

.action-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.refund-btn {
  background-color: #dc3545;
}

.refund-btn:hover:not(:disabled) {
  background-color: #c82333;
}

.cancel-btn {
  background-color: #6c757d;
}

.cancel-btn:hover:not(:disabled) {
  background-color: #5a6268;
}
</style>