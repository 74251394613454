<template>
  <div class="checkbox-container">
    <label :for="id" class="checkbox-label" :class="{ 'disabled': disabled }">
      <input 
        type="checkbox" 
        :id="id" 
        :checked="checked" 
        :disabled="disabled"
        @change="toggleCheck" 
        class="checkbox-input"
      />
      <div 
        class="checkbox-visual" 
        :class="{ 'checked': checked, 'disabled': disabled }"
      >
        <i class="fas" :class="{'fa-check': checked, 'fa-xmark': !checked}"></i>
      </div>
      <span v-if="label" class="checkbox-text">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  data() {
    return {
      checked: false,
    }
  },
  mounted() {
    this.checked = this.value;
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleCheck() {
      if(!this.disabled) {
        this.checked = !this.checked;
        this.$emit('change', this.checked);
      }
    }
  }
}
</script>

<style scoped>
.checkbox-container {
  display: inline-flex;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
}

.checkbox-label.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox-input:focus + .checkbox-visual {
  box-shadow: 0 0 0 3px rgba(51, 142, 255, 0.3);
}

.checkbox-visual {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.375rem;
  border: 2px solid rgba(45, 51, 60, 1);
  background-color: transparent;
  transition: all 0.2s ease;
}

.checkbox-visual:hover:not(.disabled) {
  border-color: rgba(51, 142, 255, 0.8);
}

.checkbox-visual.checked {
  border-color: rgba(51, 142, 255, 1);
  background-color: rgba(51, 142, 255, 0.1);
}

.checkbox-visual.checked .fa-check {
  opacity: 1;
}

.checkbox-visual.disabled:not(.checked) {
  border-color: rgba(255, 50, 50, 0.5);
}

.checkbox-visual.disabled:not(.checked) .fa-xmark {
  color: rgba(255, 50, 50, 0.5);
}

.checkbox-visual.disabled.checked {
  border-color: rgba(138, 195, 41, 0.5);
}

.checkbox-visual.disabled.checked .fa-check {
  color: rgba(138, 195, 41, 0.8);
}

.checkbox-visual .fas {
  font-size: 0.75rem;
}

.checkbox-visual .fa-check {
  color: rgba(51, 142, 255, 1);
}

.checkbox-visual .fa-xmark {
  color: rgba(45, 51, 60, 1);
}

.checkbox-text {
  font-size: 0.9rem;
  color: white;
}
</style>